// import { RouteConfig } from 'vue-router';

// import { lazyLoadPage } from '@/router/helper';

// const path = '/contact';

export enum ContactPages {
  noticePage = 'https://oc.qpit.ai/qpit/hc/notice/',
  ticketPage = 'https://oc.qpit.ai/qpit/hc/ticket/',
  articlePage = 'https://oc.qpit.ai/qpit/hc/article/'
}

// 추 후에 사용 될 수 있어서 주석 처리
// export const contactRouter: RouteConfig[] = [
//   {
//     path: `${path}/notice`,
//     name: ContactPages.noticePage,
//     component: () => lazyLoadPage('contact/Notice')
//   },
//   {
//     path: `${path}/ticket`,
//     name: ContactPages.ticketPage,
//     component: () => lazyLoadPage('contact/Ticket')
//   },
//   {
//     path: `${path}/article`,
//     name: ContactPages.articlePage,
//     component: () => lazyLoadPage('contact/Article')
//   }
// ];
