import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en.json';
import ja from './ja.json';
import ko from './ko.json';

Vue.use(VueI18n);

const languages = ['ko', 'en', 'ja'];
const message = {ko, en, ja};

const getLocale = (): string | undefined => {
    const language = navigator.language.substr(0, 2);
    if (languages.includes(language)) {
        return language
    } else {
        return 'en'
    }
};

const i18n = new VueI18n({
    locale: getLocale(),
    fallbackLocale: 'en',
    messages: message
});

const i18nTxt = (value: string): string =>
    i18n.t(value).toString() ? i18n.t(value).toString() : `${value}`;

export {i18n, i18nTxt};
