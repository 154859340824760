import Vue from 'vue';
import Vuex from 'vuex';

const noProd = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

export const rootStore = new Vuex.Store({
  strict: noProd
});
