import { i18n } from '@/commons/i18n';
import { Locale, LocaleType, ViewLang, ViewLangType } from '@/constant/common';

export const useI18n = () => {
  function setLocale(locale: LocaleType) {
    i18n.locale = locale;
  }
  function getLocale() {
    return i18n.locale;
  }
  function getLocaleByViewLang(viewLang: ViewLangType) {
    const { KOR, ENG, JPN } = ViewLang;
    const { ko, en, ja } = Locale;
    switch (viewLang) {
      case KOR:
        return ko;
      case ENG:
        return en;
      case JPN:
        return ja;
      default:
        return ko;
    }
  }
  function getViewLangByLocale(locale: LocaleType) {
    const { KOR, ENG, JPN } = ViewLang;
    const { ko, en, ja } = Locale;
    switch (locale) {
      case ko:
        return KOR;
      case en:
        return ENG;
      case ja:
        return JPN;
      default:
        return KOR;
    }
  }
  return {
    setLocale,
    getLocale,
    getLocaleByViewLang,
    getViewLangByLocale
  };
};
