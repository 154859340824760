import { ValueOf } from '@/utils/types/value-of';

export const Locale = {
  ko: 'ko',
  en: 'en',
  ja: 'ja'
};
export type LocaleType = ValueOf<typeof Locale>;

export const ViewLang = {
  KOR: 'KOR',
  JPN: 'JPN',
  ENG: 'ENG'
};
export type ViewLangType = ValueOf<typeof ViewLang>;
