





































































































import { useRouter } from '@u3u/vue-hooks';
import { defineComponent, ref } from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import { useI18n } from '@/composables/useI18n';
import { HomePages } from '@/router';
import { ContactPages } from '@/router/modules/contact';
import { ServicesPages } from '@/router/modules/services';
import { isIE, isPC } from '@/utils/browser-state';

export default defineComponent({
  name: 'GlobalFooter',
  setup() {
    const { getLocale } = useI18n();
    const { router, route } = useRouter();
    const isServicesClicked = ref(false);
    const isContactClicked = ref(false);
    const isSubFooter = ref(false);
    const isFamilyOpen = ref(false);

    const styleChangeHandler = (value: boolean) => {
      isSubFooter.value = value;
    };

    const familyOptionToggle = () => {
      isFamilyOpen.value = !isFamilyOpen.value;
    };

    function handleRouter(routeName: string) {
      window.scrollTo(0, 0);
      if (route.value.name !== routeName) {
        router.push({ name: routeName });
      }
    }

    function handleContactClicked(routeName: string) {
      window.open(`${routeName}?lang=${getLocale()}`, '_blank');
    }

    function handleDemoClicked() {
      if (isIE()) {
        window.alert(i18nTxt('demo_ie_alert_msg'));
        return;
      }
      let urlPath = '';
      if (isPC()) {
        urlPath = 'https://demo.qpit.kr/login';
      } else {
        urlPath = `${ContactPages.articlePage}?lang=${getLocale()}`;
      }
      window.open(urlPath, '_blank');
    }

    return {
      i18nTxt,
      isPC,
      styleChangeHandler,
      familyOptionToggle,
      handleRouter,
      handleContactClicked,
      handleDemoClicked,
      ServicesPages,
      HomePages,
      ContactPages,
      isServicesClicked,
      isContactClicked,
      isSubFooter,
      isFamilyOpen
    };
  }
});
