const onresize = (): {
  browserMode: string;
  browserWidth: number;
  browserHeight: number;
} => {
  const params = {
    browserMode: document.body.clientWidth > 767 ? 'pc' : 'mobile',
    browserWidth: document.body.clientWidth,
    browserHeight: document.body.clientHeight
  };
  return params;
};

const isPC = (): boolean => {
  const mode = onresize().browserMode;
  return mode === 'pc';
};

const isIE = (): boolean => {
  const agent = navigator.userAgent.toLowerCase();
  if (
    navigator.userAgent.search('Trident') !== -1 ||
    agent.indexOf('mise') !== -1
  )
    return true;
  return false;
};

export { onresize, isPC, isIE };
