import { RouteConfig } from 'vue-router';

import { lazyLoadPage } from '@/router/helper';

const path = '/services';

export enum ServicesPages {
  AILensPage = 'AILensPage',
  AIWeavePage = 'AIWeavePage',
  AITagPage = 'AITagPage',
  About = 'About',
  Blog = 'Blog'
}

export const servicesRouter: RouteConfig[] = [
  {
    path: `${path}/ai-lens`,
    name: ServicesPages.AILensPage,
    component: () => lazyLoadPage('services/AILens')
  },
  {
    path: `${path}/ai-weave`,
    name: ServicesPages.AIWeavePage,
    component: () => lazyLoadPage('services/AIWeave')
  },
  {
    path: `${path}/ai-tag`,
    name: ServicesPages.AITagPage,
    component: () => lazyLoadPage('services/AITag')
  }
];
