












import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'QModal',
  props: {
    isShow: {
      require: true,
      type: Boolean
    }
  },
  setup() {
    return {};
  }
});
