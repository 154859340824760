import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { lazyLoadPage } from './helper';
import { servicesRouter } from './modules/services';

Vue.use(VueRouter);

export enum HomePages {
  HomePage = 'HomePage',
  AboutPage = 'About',
  BlogPage = 'Blog'
}

const routes: Array<RouteConfig> = [
  ...servicesRouter,
  {
    path: '/',
    name: HomePages.HomePage,
    component: () => lazyLoadPage('Home')
  },
  {
    path: '/about',
    name: HomePages.AboutPage,
    component: () => lazyLoadPage('About')
  },
  {
    path: '/blog',
    name: HomePages.BlogPage,
    component: () => lazyLoadPage('Blog')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

// 라우팅 이전 조치
router.beforeEach(async (to, from, next) => {
  return next();
});

// 라우팅 이후 조치
router.afterEach(() => {
  //
});

export default router;
