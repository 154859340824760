














import { defineComponent, Ref, ref } from '@vue/composition-api';

import GlobalFooter from '@/components/global/GlobalFooter.vue';
import GlobalHeader from '@/components/global/GlobalHeader.vue';

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  setup() {
    const isHome = ref(true);
    const headerRef: Ref<any | null> = ref(null);
    const footerRef: Ref<any | null> = ref(null);

    // main, sub css 다르게 적용하기 위해서 추가
    const isHomeFocus = (value: boolean, isSubFooter?: boolean) => {
      isHome.value = value;
      if (isSubFooter) {
        footerRef.value.styleChangeHandler(true);
      } else {
        footerRef.value.styleChangeHandler(false);
      }
    };

    const homeHeaderStyleChange = (value: boolean) => {
      headerRef.value.isWhiteCheck(value);
    };

    return {
      isHome,
      headerRef,
      footerRef,
      isHomeFocus,
      homeHeaderStyleChange
    };
  }
});
