import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';

import '@babel/polyfill';
import '@/configs';
import '@/assets/css/main.css';
import '@/assets/css/sub.css';
import 'intersection-observer';
import App from './App.vue';
import router from './router';
import { rootStore } from './store';
import { i18n } from '@/commons/i18n/index';
import { isPC } from '@/utils/browser-state';
Vue.use(VueCompositionApi);
Vue.config.productionTip = false;

const onresize = () => {
  if (isPC()) window.location.reload();
};

window.addEventListener('resize', onresize);

new Vue({
  i18n,
  router,
  store: rootStore,
  render: (h) => h(App)
}).$mount('#app');
