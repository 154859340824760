












































































































import { useRouter } from '@u3u/vue-hooks';
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch
} from '@vue/composition-api';

import { i18nTxt } from '@/commons/i18n/index';
import QModal from '@/components/services/QModal.vue';
import { useI18n } from '@/composables/useI18n';
import { HomePages } from '@/router';
import { ContactPages } from '@/router/modules/contact';
import { ServicesPages } from '@/router/modules/services';
import { isIE, isPC } from '@/utils/browser-state';

export default defineComponent({
  components: { QModal },
  name: 'GlobalHeader',
  setup(props, { root }) {
    const { getLocaleByViewLang, getViewLangByLocale, setLocale, getLocale } =
      useI18n();
    const { router, route } = useRouter();
    const isServicesClicked = ref(false);
    const isContactClicked = ref(false);
    const isLnbHover = ref(false);
    const isMobileMenuOpen = ref(false);
    const isLangClicked = ref(false);
    const isConsoleClicked = ref(false);
    const isSubHeader = ref(false);
    const isScroll = ref(false);
    const nowLang = ref(getViewLangByLocale(getLocale()));
    const listGnbEl: Ref<HTMLElement | null> = ref(null);

    const styleChangeHandler = (value: boolean) => {
      isSubHeader.value = value;
    };

    function handleLogoClicked() {
      if (route.value.name !== HomePages.HomePage)
        router.push({ name: HomePages.HomePage });
    }

    function handleLangClicked(e: Event) {
      const lang = (e.target as Element).innerHTML;
      setLocale(getLocaleByViewLang(lang));
      nowLang.value = getViewLangByLocale(getLocale());
      isLangClicked.value = false;
    }

    function handleRouter(routeName: string) {
      isMobileMenuOpen.value = false;
      isLnbHover.value = false;
      if (routeName === HomePages.HomePage) {
        window.scrollTo(0, 0);
      }
      if (route.value.name !== routeName) {
        router.push({ name: routeName });
      }
    }

    function scrollStyleHandler() {
      isScroll.value = window.pageYOffset > 0;
    }

    function handleDemoClicked() {
      if (isIE()) {
        window.alert(i18nTxt('demo_ie_alert_msg'));
        return;
      }
      let urlPath = '';
      if (isPC()) {
        urlPath = 'https://demo.qpit.kr/login';
      } else {
        urlPath = `${ContactPages.articlePage}?lang=${getLocale()}`;
      }
      window.open(urlPath, '_blank');
    }

    function handleConsoleClicked(url: string) {
      isConsoleClicked.value = false;
      window.open(url, '_blank');
    }

    function handleContactClicked(routeName: string) {
      isMobileMenuOpen.value = false;
      isLnbHover.value = false;
      let urlPath = root.$router.resolve({ name: routeName });
      window.open(urlPath.href, '_blank');
    }

    const isServicesVisible = computed(() => {
      return isPC() ? isLnbHover.value : isServicesClicked.value;
    });

    const isContactVisible = computed(() => {
      return isPC() ? isLnbHover.value : isContactClicked.value;
    });

    const isActive = computed(() => {
      return isPC() ? isLnbHover.value : false;
    });

    function removeOnClassInGnb() {
      const list = listGnbEl.value;
      list?.childNodes.forEach((el) => {
        (el as HTMLElement).classList.remove('on');
      });
    }

    function getGnbItemEl(routeName: string): HTMLElement | null {
      const list = listGnbEl.value;
      let gnbItemEl = null;
      list?.childNodes.forEach((el) => {
        const gnbName = (el.firstChild as HTMLElement).innerHTML;
        switch (routeName) {
          case ServicesPages.AILensPage:
            if (gnbName === 'AI Lens') gnbItemEl = el;
            break;
          case ServicesPages.AIWeavePage:
            if (gnbName === 'AI Weave') gnbItemEl = el;
            break;
          case ServicesPages.AITagPage:
            if (gnbName === 'AI Tag') gnbItemEl = el;
            break;
          case HomePages.BlogPage:
            if (gnbName === 'Blog') gnbItemEl = el;
            break;
          default:
            gnbItemEl = null;
        }
      });

      return gnbItemEl;
    }

    watch(
      () => route.value.name,
      (name) => {
        removeOnClassInGnb();
        getGnbItemEl(name || '')?.classList.add('on');
      }
    );

    onMounted(() => {
      window.addEventListener('scroll', scrollStyleHandler);
    });

    return {
      HomePages,
      ServicesPages,
      ContactPages,
      isLnbHover,
      isActive,
      isMobileMenuOpen,
      isLangClicked,
      isConsoleClicked,
      isServicesClicked,
      isContactClicked,
      isSubHeader,
      isScroll,
      isServicesVisible,
      isContactVisible,
      nowLang,
      handleLogoClicked,
      handleLangClicked,
      handleRouter,
      handleDemoClicked,
      handleConsoleClicked,
      handleContactClicked,
      styleChangeHandler,
      listGnbEl
    };
  }
});
